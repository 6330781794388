// ApiContext.js
import React, { createContext, useState } from 'react';

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
  const [apiUrl, setApiUrl] = useState(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  );
  const [deepLink, setDeepLink] = useState('');
  const [affiliateName, setAffiliateName] = useState('');
  const [iapticAppName, setIapticAppName] = useState('');
  const [iapticSecretKey, setIapticSecretKey] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyContactEmail, setCompanyContactEmail] = useState('');
  const [companyAffiliatePaymentPercentage, setCompanyAffiliatePaymentPercentage] = useState('');
  const [companyOfferCouponCodes, setCompanyOfferCouponCodes] = useState([]);
  const [companyUnassignedDeepLinks, setCompanyUnassignedDeepLinks] = useState([]);
  const [branchApiEndpoint] = useState('https://api2.branch.io/v1/url');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [priceId, setPriceId] = useState('');
  const [packageName, setPackageName] = useState('');

  const value = {
    apiUrl,
    setApiUrl,
    deepLink,
    setDeepLink,
    affiliateName,
    setAffiliateName,
    iapticAppName,
    setIapticAppName,
    iapticSecretKey,
    setIapticSecretKey,
    companyName,
    companyAffiliatePaymentPercentage,
    setCompanyAffiliatePaymentPercentage,
    companyOfferCouponCodes,
    setCompanyOfferCouponCodes,
    companyUnassignedDeepLinks,
    setCompanyUnassignedDeepLinks,
    setCompanyName,
    companyContactEmail,
    setCompanyContactEmail,
    branchApiEndpoint,
    subscriptionStatus,
    setSubscriptionStatus,
    priceId,
    setPriceId,
    packageName,
    setPackageName,
  }

  return (
    <ApiContext.Provider
      value={
        value
      }
    >
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
