import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateSelfSearchEvents = ({
    tableData,
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [paymentFilter, setPaymentFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'

    const filteredTableData = tableData
        .filter(event => paymentFilter === 'all' || event.status === paymentFilter)
        .map((event, index) => (
            <tr key={event.id}>
                <td>{event.endUserUUID}</td>
                <td>{event.eventUUID}</td>
                <td>{event.eventName}</td>
                <td>{formatDateOutput(event.dateTime._seconds)}</td>
                <td className={event.status === 'paid' ? 'text-green-600' : 'text-red-600'}>
                    {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                </td>
            </tr>
        ));

    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Events
            </h2>
            <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                <div>
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="flex-grow">
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-grow">
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="End Date"
                    />
                </div>
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th>End User UUID</th>
                        <th>Event UUID</th>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTableData}
                </tbody>
            </table>
        </div>
    );
};

// Helper component for date input
const DateInputWithPlaceholder = ({ id, value, onChange, placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e) => {
        setIsFocused(true);
        e.target.type = 'date';
    };

    const handleBlur = (e) => {
        if (!e.target.value) {
            e.target.type = 'text';
        }
        setIsFocused(false);
    };

    return (
        <div className="relative">
            <input
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
                style={{ width: '100%', minWidth: '180px' }}
            />
        </div>
    );
};

// Helper to format timestamp from seconds
const formatDateOutput = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
};

export default AffiliateSelfSearchEvents;
