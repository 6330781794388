import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import { Button } from '../../components/Button';
import * as Sentry from "@sentry/react";

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function convertUnixTimestampToDate(timestamp) {
    // Create a new JavaScript Date object based on the Unix timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // Get the day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so we need to add 1
    const year = date.getFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
}

const Billing = () => {
    const { setSubscriptionStatus, subscriptionStatus, priceId, packageName } = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const auth = getAuth();
    const [noEmailFound, setNoEmailFound] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [current_period_end, set_current_period_end]=useState(null);
    const [current_period_start, set_current_period_start]=useState(null);
    const [latest_invoice, set_latest_invoice] = useState(null);
    const [invoice_pdf, set_invoice_pdf] = useState(null);

    const fetchClientSecret = useCallback(() => {
        return fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session-billing`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                priceId,
                email: auth.currentUser?.email,
            })
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, [priceId, auth.currentUser.email]);

    useEffect(() => {
        if (priceId && !subscriptionStatus && auth.currentUser) {
            fetchClientSecret().then(secret => {
                setClientSecret(secret);
            });
        }
    }, [priceId, subscriptionStatus, fetchClientSecret, auth.currentUser]);

    useEffect(()=>{
        if (auth.currentUser, user?.email) {
            fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: auth.currentUser.email })
            }).then((res) => res.json()).then(data => {
                if (data?.status === 'Email not found') {
                    console.log("If you have cancelled your subscription, you can resubscribe here");
                    setNoEmailFound(true);
                    Sentry.captureException("Billing error - SubscriptionId not available (email not found)");
                    return;
                }
                setNoEmailFound(false);
                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/retrieveSubscription`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ subscriptionId: data?.data?.subscriptionId })
                }).then((res) => res.json()).then(data => {
                    console.log("data.subscription: ", data.subscription);
                    console.log("subscriptionStatus: ", subscriptionStatus);
                    console.log("priceId: ", priceId);
                    if (data.subscription === null || data.subscription === undefined) {
                        console.log("Billing error - SubscriptionId not available (subscription not found)");
                        return;
                    }

                    const {current_period_end,current_period_start,latest_invoice} = data?.subscription;
                    
                    set_current_period_end(current_period_end);
                    set_current_period_start(current_period_start);
                    set_latest_invoice(latest_invoice);

                    // get invoice info
                    fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-invoice`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ invoiceId: latest_invoice })
                    }).then((res) => res.json()).then(data => {
                        const { invoice_pdf } = data.invoice;
                        set_invoice_pdf(invoice_pdf);
                    });
                })
            })
        }
    }, [auth.currentUser?.email, user?.email])

    const options = { clientSecret };

    const handleDownload = async()=>{
        window.location.href=invoice_pdf;
    };

    const [isCustomerId, setCustomerId] = useState(null);
    const [url, setUrl] = useState(null);
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user?.email })
      }).then((res) => res.json()).then(data => {
        setCustomerId(data?.data?.customerId)
      })
    }, [user?.email])
  
    const handleManage = async() => {
      await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/manage-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ customerId: isCustomerId })
      }).then((res) => res.json()).then(data => {
        window.location.href=data.url;
      })
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
        {
            (!priceId || subscriptionStatus == null || noEmailFound ) ? (
                // No Subscription Found
                <div>
                    <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                        No Subscription Found
                    </h1>
                    <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                        It seems like you don't yet have a subscription to access Insert Affiliate.
                    </p>
                    <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                        <a href="https://insertaffiliate.com/#pricing" className="text-purple-900">
                            To get started, check out all available packages here
                        </a>.
                    </p>
                </div>
            ) : (
                priceId && subscriptionStatus === true && subscriptionStatus !== null ? (
                    // Manage Billing - Active Subscription
                    <>
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Manage Your Billing
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Subscription Status: Active
                        </p>
                        <p>
                            Active subscription: <span className='text-green-700'>{packageName}</span>
                        </p>
                        <p>
                            Your subscription start date is {convertUnixTimestampToDate(current_period_start)}
                        </p>
                        <p>
                            Your next payment date is {convertUnixTimestampToDate(current_period_end)}
                        </p>
                        {invoice_pdf && (
                            <Button onClick={handleDownload} className="mx-auto mt-4 pt-2">
                                Download Invoice
                            </Button>
                        )}
                        {isCustomerId && (
                            <div className='pb-4'>
                                <Button onClick={handleManage} className="mx-auto mt-4 pt-2">
                                    Manage Subscription
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    // Billing Page - Inactive Subscription
                    
                    <div>
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Billing Page
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Your subscription status is Inactive
                        </p>
                        <p>
                            You can subscribe again to your previous package, {packageName}.
                        </p>
                        <div className='p-6'>
                            <div id="checkout">
                                {clientSecret && (
                                    <EmbeddedCheckoutProvider
                                        stripe={stripePromise}
                                        options={options}
                                    >
                                        <EmbeddedCheckout />
                                    </EmbeddedCheckoutProvider>
                                )}
                            </div>
                        </div>
                    </div>
                )
            )
        }
    </div>
    );
};

export default Billing;