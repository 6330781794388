import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container.js';
import { ApiContext } from '../../contexts/ApiContext.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { collection, getDoc, updateDoc, doc } from 'firebase/firestore'; // Import firestore functions
import { db } from '../../firebase.js';
import { Button } from '../../components/Button.js';
import CreatableSelect from 'react-select/creatable';

const Settings = () => {
  const {
    iapticAppName,
    setIapticAppName,
    iapticSecretKey,
    setIapticSecretKey,
    companyName,
    setCompanyName,
    companyContactEmail,
    setCompanyContactEmail,
    companyAffiliatePaymentPercentage,
    setCompanyAffiliatePaymentPercentage,
    companyOfferCouponCodes,
    setCompanyOfferCouponCodes
  } = useContext(ApiContext);
  
  const [notification, setNotification] = useState('');
  const [showIapticSecret, setShowIapticSecret] = useState(false);
  const [localCompanyName, setLocalCompanyName] = useState(companyName || "");
  const [localCompanyContactEmail, setLocalCompanyContactEmail] = useState(companyContactEmail || "");
  const [localCompanyAffiliatePaymentPercentage, setLocalCompanyAffiliatePaymentPercentage] = useState(companyAffiliatePaymentPercentage || 25);
  const [localCompanyOfferCouponCodes, setLocalCompanyOfferCouponCodes] = useState(() => {
    if (Array.isArray(companyOfferCouponCodes) && companyOfferCouponCodes !== null) {
        return companyOfferCouponCodes.map(code => ({
            value: code.value,
            label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
            expiry: code.expiry
        }));
    }
    return []; // Default to empty array if companyOfferCouponCodes is null or not an array
  });
  const [localIapticAppName, setLocalIapticAppName] = useState(iapticAppName || "");
  const [localIapticSecretKey, setLocalIapticSecretKey] = useState(iapticSecretKey || "");

  // Update local when remote is retrieved
  useEffect(() => {
    if (companyName !== '' && companyName !== localCompanyName) {
      setLocalCompanyName(companyName);
    }
  }, [companyName]);
  useEffect(() => {
    if (companyContactEmail !== '' && companyContactEmail !== localCompanyContactEmail) {
      setLocalCompanyContactEmail(companyContactEmail);
    }
  }, [companyContactEmail]);
  useEffect(() => {
    if (iapticAppName !== '' && iapticAppName !== localIapticAppName) {
      setLocalIapticAppName(iapticAppName);
    }
  }, [iapticAppName]);
  useEffect(() => {
    if (iapticSecretKey !== '' && iapticSecretKey !== localIapticSecretKey) {
      setLocalIapticSecretKey(iapticSecretKey);
    }
  }, [iapticSecretKey]);
  useEffect(() => {
    if (companyAffiliatePaymentPercentage != undefined && companyAffiliatePaymentPercentage !== '' && companyAffiliatePaymentPercentage !== localCompanyAffiliatePaymentPercentage) {
      console.log("setting companyAffiliatePaymentPercentage: ", companyAffiliatePaymentPercentage);
      setLocalCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
    }
  }, [companyAffiliatePaymentPercentage]);
  useEffect(() => {
    if (companyOfferCouponCodes && Array.isArray(companyOfferCouponCodes)) {
      setLocalCompanyOfferCouponCodes(
        companyOfferCouponCodes.map(code => ({
          value: code.value,
          label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
          expiry: code.expiry
        }))
      );
    }
  }, [companyOfferCouponCodes]);
  

  const user = FetchUserAndFirebaseDocsOnAuth();

  const [selectedCode, setSelectedCode] = useState(null);

  const handleChange = (newValue, actionMeta) => {
    setSelectedCode(newValue);
  };

  const handleCreate = (inputValue) => {
    const expiryDate = prompt('Enter expiry date (YYYY-MM-DD):');
    const newOption = {
        value: inputValue,
        label: `${inputValue} (expires: ${new Date(expiryDate).toLocaleDateString()})`,
        expiry: expiryDate
    };
    setLocalCompanyOfferCouponCodes((prevOptions) => [...prevOptions, newOption]);
    setSelectedCode(newOption);
  };

  const updateAndSaveSettings = async ({ companyName, companyContactEmail, iapticAppName, iapticSecretKey, companyAffiliatePaymentPercentage, companyOfferCouponCodes }) => {
    // Assuming your Firestore collection is named 'copanies'
    const companiesRef = collection(db, 'Companies');

    const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID

    const privateRef = collection(userDocRef, 'Private');
    const privateDocRef = doc(privateRef, 'Credentials');

    console.log('Updating Firestore document...companyOfferCouponCodes: ', companyOfferCouponCodes);
    console.log('companyName: ', companyName);
    console.log('companyAffiliatePaymentPercentage: ', companyAffiliatePaymentPercentage);

    try {
        const userDocSnapshot = await getDoc(userDocRef);

        // Update the Firestore document with the new settings
        await updateDoc(userDocRef, {
          companyName: companyName,
          companyContactEmail: companyContactEmail,
          companyAffiliatePaymentPercentage: companyAffiliatePaymentPercentage,
          companyOfferCouponCodes: companyOfferCouponCodes
        });

        await updateDoc(privateDocRef, {
          iapticAppName: iapticAppName,
          iapticSecretKey: iapticSecretKey
        });

        setCompanyName(companyName);
        setCompanyContactEmail(companyContactEmail);
        setIapticAppName(iapticAppName);
        setIapticSecretKey(iapticSecretKey);
        setCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
        setCompanyOfferCouponCodes(companyOfferCouponCodes);

        setNotification('Settings have been saved successfully.');
        setTimeout(() => setNotification(''), 3000); // Auto-hide after 3 seconds
    } catch (error) {
        console.error('Error updating Firestore document:', error);
    }
  };

  const toggleSecretIapticVisibility = () => {
    setShowIapticSecret(!showIapticSecret);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const offerCouponCodesAsStrings = localCompanyOfferCouponCodes.map(option => ({
      value: option.value,
      expiry: option.expiry
    }));
    updateAndSaveSettings({
      companyName: localCompanyName,
      companyContactEmail: localCompanyContactEmail,
      iapticAppName: localIapticAppName,
      iapticSecretKey: localIapticSecretKey,
      companyAffiliatePaymentPercentage: localCompanyAffiliatePaymentPercentage,
      companyOfferCouponCodes: offerCouponCodesAsStrings
    });
  };

  const [isCustomerId, setCustomerId] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: user?.email })
    }).then((res) => res.json()).then(data => {
      setCustomerId(data?.data?.customerId)
    })
  }, [user?.email])

  const handleManage = async() => {
    await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/manage-subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customerId: isCustomerId })
    }).then((res) => res.json()).then(data => {
      window.location.href=data.url;
    })
  }

  return (
    <div>
      <Container>
        {notification && (
          <div className="bg-green-500 text-white p-2 rounded mb-4">
            {notification}
          </div>
        )}
        <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
          <div className="pb-4">
            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
              Settings
            </h1>
            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
              This is all we need to get started. Most questions can be answered by 
              <a
                href="https://docs.insertaffiliate.com"
                target="_blank"
                className="text-purple-600 hover:underline pl-1">
                 reading the docs
              </a>.
            </p>
          </div>
        
          <form onSubmit={handleSubmit} className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="companyName"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Company Name:
              </label>
              <p className="text-xs text-gray-500 mb-2">
                The official name of the company that affiliates will see when they join your program.
              </p>
              <input
                type="text"
                id="companyName"
                value={localCompanyName || ""}
                onChange={(e) => setLocalCompanyName(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="companyContactEmail"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Contact Email:
              </label>
              <p className="text-xs text-gray-500 mb-2">
                The email address that affiliates can use to reach out for questions or support.
              </p>
              <input
                type="email"
                id="companyContactEmail"
                value={localCompanyContactEmail || ""}
                onChange={(e) => setLocalCompanyContactEmail(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="iapticAppName"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Iaptic App Name:
              </label>
              <p className="text-xs text-gray-500 mb-2">
                The name of the Iaptic app integrated with this platform.<a href="https://docs.insertaffiliate.com/iaptic#important-settings" target="_blank" className="text-purple-600 hover:underline pl-1">Learn more</a>.
              </p>
              <p className="text-xs text-gray-500 mb-2 mt-2">
                Please note, this is CASE SENSITIVE. 
              </p>
              <div>
                <input
                  type="text"
                  id="iapticAppName"
                  value={localIapticAppName || ""}
                  onChange={(e) => setLocalIapticAppName(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="iapticSecretKey"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Iaptic Secret:
              </label>
              <p className="text-xs text-gray-500 mb-2">
                The secret key for securely connecting the Iaptic app to your platform. <a href="https://www.iaptic.com/settings)" target="_blank" className="text-purple-600 hover:underline pl-1">Link to Iaptic's Settings</a>.
              </p>
              <div className="relative">
                <input
                  type={showIapticSecret ? 'text' : 'password'}
                  id="iapticSecretKey"
                  value={localIapticSecretKey || ""}
                  onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={toggleSecretIapticVisibility}
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                >
                  {showIapticSecret ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
            <div>
              <label
                htmlFor="localCompanyAffiliatePaymentPercentage"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Affiliate Payment Percentage:
              </label>
              <p className="text-xs text-gray-500 mb-2">
                The percentage of the revenue generated by affiliates that you will pay them. Minimum is 5%.
              </p>
              <div className="relative">
                <input
                  id="localCompanyAffiliatePaymentPercentage"
                  value={localCompanyAffiliatePaymentPercentage || 5}
                  type="number"
                  min="5"
                  max="100"
                  step="0.01"
                  placeholder="The percentage of the generated revenue that you will pay affiliates"
                  onChange={(e) => setLocalCompanyAffiliatePaymentPercentage(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="localCompanyOfferCouponCodes"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Offer codes
              </label>
              <p className="text-xs text-gray-500 mb-2">
                Select or add new offer codes that your affiliates links can be set to automatically assign to customers. These will help track affiliate performance.
              </p>
              <CreatableSelect
                id="localCompanyOfferCouponCodes"
                isClearable
                value={selectedCode}
                onChange={handleChange}
                onCreateOption={handleCreate}
                options={localCompanyOfferCouponCodes}
                placeholder="Select or add a new offer code"
                classNamePrefix="react-select"
              />
            </div>
          </form>
          <Button onClick={handleSubmit} className="mx-auto mt-4 pt-2">Save Changes</Button>
         
        </div>
      </Container>
    </div>
  );
};

export default Settings;
