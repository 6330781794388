import React, { useState, useContext, useEffect } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Container } from '../../components/Container';
import { db } from '../../firebase';
import { collection, doc, getDocs } from 'firebase/firestore';
import { ApiContext } from '../../contexts/ApiContext';
import { OnboardingModal } from '../../components/OnboardingModal';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { getAuth } from "firebase/auth";

ChartJS.register(...registerables);

const AdminHomeDashboard = () => {
    const {iapticAppName, iapticSecretKey, apiUrl} = useContext(ApiContext);
    const [totalAffiliates, setTotalAffiliates] = useState(0);
    const [totalAffiliatesPercentageIncrease, setTotalAffiliatesPercentageIncrease] = useState(0);
    const [affiliateGrowthData, setAffiliateGrowthData] = useState({ labels: [], datasets: [] });
    const [monthlyStatsData, setMonthlyStatsData] = useState({ labels: [], datasets: [] });
    const [error, setError] = useState(null);
    const [showOnboarding, setShowOnboarding] = useState(false);

    const auth = getAuth();
    const user = FetchUserAndFirebaseDocsOnAuth();

    useEffect(() => {
        const hasShownOnboarding = localStorage.getItem('hasShownOnboardingTwo');
        if (user && !hasShownOnboarding) {
            setShowOnboarding(true);
        }
    }, [user]);

    const handleCloseModal = () => {
        setShowOnboarding(false);
        localStorage.setItem('hasShownOnboardingTwo', 'true');
    };

    // updating subscriptions status
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/update-companies-subscription-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: auth.currentUser.email })
        })
        .then((res) => res.json()).then(data => {
            // console.log({
            //     'subscription updated on company collection': data.status
            // })
        })
    }, [user?.email, auth.currentUser.email]);

    useEffect(() => {
        if (user && iapticAppName && iapticSecretKey) {
            updateTotalAffiliatesDashboard();
            getCompanyStats();
        }
    }, [user, iapticAppName, iapticSecretKey]);

    useEffect(() => {
        // Check if the user is loaded and either iapticAppName or iapticSecretKey is still an empty string, their settings are not completed yet
        if (user && (iapticAppName === '' || iapticSecretKey === '')) {
            // Perform the alternative action here if the values are still empty
            console.log("Values are still empty, doing something else...");
            setShowOnboarding(true);

        }
    }, [user, iapticAppName, iapticSecretKey]);

    const calculateAffiliatesPerMonth = (affiliates, monthStartDates) => {
        const counts = monthStartDates.map(date => {
            return affiliates.filter(affiliate => affiliate.createdAt && affiliate.createdAt < date).length;
        });
        return counts;
    };

    const getLastSixMonthsStartDates = () => {
        const dates = [];
        const currentDate = new Date();
        for (let i = 5; i >= 0; i--) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
            dates.push(date);
        }
        return dates;
    };

    const getLastSixMonths = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const currentMonth = new Date().getMonth();
        const lastSixMonths = [];
    
        for (let i = 5; i >= 0; i--) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastSixMonths.push(months[monthIndex]);
        }
    
        return lastSixMonths;
    };

    const fetchFirestoreAffiliates = async (uid) => {
        try {
            if (!uid) {
                throw new Error("User UID is not provided");
            }
    
            const companiesRef = collection(db, 'Companies');
            const userDocRef = doc(companiesRef, uid);
            const affiliateCollection = collection(userDocRef, 'Affiliates');
            const affiliateCollectionSnapshot = await getDocs(affiliateCollection);
    
            const groupedDataFirebase = [];

            affiliateCollectionSnapshot.forEach((doc) => {
                const affiliateEntry = {
                    email: doc.data().email,
                    affiliatename: doc.data().affiliatename,
                    deeplinkurl: doc.data().deeplinkurl,
                    createdAt: doc.data().createdAt ? new Date(doc.data().createdAt) : null
                };
                if (!affiliateEntry.createdAt) {
                    console.warn(`Affiliate ${doc.id} does not have a valid createdAt field`);
                }
                groupedDataFirebase.push(affiliateEntry);
            });
            return groupedDataFirebase;
        } catch (error) {
            setError("Failed to fetch affiliates. Please check all of your details are correct in settings. If you believe they are correct, please try again later.");
            return []; // Return an empty array on error to handle gracefully
        }
    };

    const getCompanyStats = async () => {
        try {
            const response = await fetch(`${apiUrl}/returnCompanyStats?appName=${iapticAppName}&secretKey=${iapticSecretKey}`);
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            const data = await response.json();

            const monthlyStats = data.monthlyStats;
            const lastSixMonthsStats = monthlyStats.slice(-6);
            const labels = lastSixMonthsStats.map(stat => stat.date);
            const amountUSD = lastSixMonthsStats.map(stat => stat.amountUSD);

            setMonthlyStatsData({
                labels: labels,
                datasets: [
                    {
                        label: 'Monthly Stats ($)',
                        data: amountUSD,
                        backgroundColor: 'rgba(255, 215, 0, 1)',
                        borderColor: 'rgba(255, 215, 0, 1)',
                        borderWidth: 1,
                    },
                ],
            });
        } catch (error) {
            // console.error("Error fetching company stats:", error);
            setError(
                <>
                    Failed to fetch company stats. Please check that your Iaptic App Name and Secret Key are correctly entered in the 
                    <a href="https://app.insertaffiliate.com/settings" target="_blank" rel="noopener noreferrer"> settings</a>. You can refer to the <a href="https://docs.insertaffiliate.com/iaptic#required-settings" className="text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500" target="_blank" rel="noopener noreferrer">documentation for guidance</a>.
                </>
            );
        }
    };

    const updateTotalAffiliatesDashboard = async () => {
        try {
            const firebaseAffiliates = await fetchFirestoreAffiliates(user?.uid);
            setTotalAffiliates(firebaseAffiliates.length);
            const lastSixMonthsStartDates = getLastSixMonthsStartDates();
            const affiliateCounts = calculateAffiliatesPerMonth(firebaseAffiliates, lastSixMonthsStartDates);

            const monthIndex = 5; // Index of the sixth month in the array
            const sixthMonthCount = affiliateCounts[monthIndex];
            const fifthMonthCount = affiliateCounts[monthIndex - 1];
            const percentageChange = fifthMonthCount === 0 ? 0 : ((sixthMonthCount - fifthMonthCount) / fifthMonthCount) * 100;
            setTotalAffiliatesPercentageIncrease(percentageChange);

            setAffiliateGrowthData({
                labels: getLastSixMonths(),
                datasets: [
                    {
                        data: affiliateCounts,
                        fill: false,
                        borderColor: 'rgba(255, 215, 0, 1)',
                        tension: 0.1,
                        label: "Total Affiliates",
                    },
                ],
            });
        } catch (error) {
            console.error("Error updating total affiliates dashboard: ", error);
            setError("Failed to update total affiliates dashboard. Please check all of your details are correct in settings, and please try again later.");
        }
    };

    return (
        <div>
            <Container>
                {showOnboarding && <OnboardingModal type="admin" onClose={handleCloseModal} />}
                <div className="flex flex-col ">
                    <div className="pb-4">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Welcome back!
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Here's your quick status update at a glance.
                        </p>
                    </div>
                    {error && (
                        <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                            {error}
                        </div>
                    )}
                    <div className="bg-white p-6 rounded-lg shadow-md ">
                        {/* Box to the left */}
                        <div className="mr-16 relative flex items-center mb-4">
                            <h2 className="text-xl font-semibold text-gray-900">Total Affiliates: {totalAffiliates}</h2>
            
                            <div className="flex items-center ml-2">
                                <span className="text-green-700 flex items-center jutify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 mr-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d={totalAffiliatesPercentageIncrease >= 0 ? "M5 10l7-7m0 0l7 7m-7-7v18" : "M19 14l-7 7m0 0l-7-7m7 7V3"}
                                        />
                                    </svg>
                                    {totalAffiliatesPercentageIncrease >= 0 ? `+${totalAffiliatesPercentageIncrease.toFixed(2)}%` : `${totalAffiliatesPercentageIncrease.toFixed(2)}%`}
                                </span>
                            </div>
                        </div>
                        {/* Line Chart on the right */}
                        <div className="relative flex-grow">
                            <Line
                                data={affiliateGrowthData}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* Bar Chart for Monthly Stats */}
                    <div className="bg-white p-6 rounded-lg shadow-md mt-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">Monthly Revenue ($)</h2>
                        <div className="relative flex-grow">
                            <Bar
                                data={monthlyStatsData}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default AdminHomeDashboard;
